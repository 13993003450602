<template>
  <div>
    <b-row fluid class="mx-0 mb-2 mb-sm-3 justify-content-between">
      <h4 class="d-flex align-items-center mb-0">
        {{ tomorrow }}
      </h4>
      <b-button type="button" variant="primary" id="resRefresh" @click="getResData()" class="mx-0">
        <Icon type="f" /> {{ $t('btn.f') }}
      </b-button>
    </b-row>
    <CustomerTable ref="table" suffix="resTabTmw"
        :query="resTabDef" :noHitText="noHitText || $t('res.rno')"
        showShow showAccept showReject showPagination />
  </div>
</template>

<script>
import Icon from '@/components/Icon';
import { utcToZonedTime } from 'date-fns-tz';
import CustomerTable from '@/components/reservations/CustomerTable';

export default {
  data() {
    return {
      tomorrow: null,
      resTabDef: { q: 'startAt:ge:{T+1},startAt:le:{T+2},userUuid4:n:{U}', s: '+startAt', p: '20' },
    }
  },
  created() {
    this.tomorrow = this.getTomorrow();
  },
  computed: {
  },
  methods: {
    getResData() { 
      this.tomorrow = this.getTomorrow();
      this.$refs['table'].getResData();
    },
    getTomorrow() {
      let s = utcToZonedTime(Date.now(), this.$store.state.settings.timezone);
      s.setDate(s.getDate() + 1);
      return this.$d(s, 'dl');
    },
  },
  props: {
    noHitText: {
      type: String,
      default: null,
    },
  },
  components: {
    CustomerTable,
    Icon,
  },
}
</script>
