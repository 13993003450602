<template>
  <div :id="id">
    <video :id="id+`Cam`" ref="cam" class="camera" muted playsinline :style="`width:`+size+`px;`" />
  </div>
</template>

<script>
import QrScanner from 'qr-scanner';
// import QrScannerWorkerPath from '!!file-loader!@/@/node_modules/qr-scanner/qr-scanner-worker.min.js';
import QrScannerWorkerPath from '!!file-loader!@/plugins/qr-scanner-worker.min.js';

export default {
  data() {
    return {
      loading: false,
      data: null,
    };
  },
  mounted() {
    QrScanner.WORKER_PATH = QrScannerWorkerPath;

    if (this.$store.state.iface.qrScanner !== null) {
      this.$store.state.iface.qrScanner.destroy();
      this.$store.commit('setScanner', null);
    }
    let scanner = new QrScanner(this.$refs.cam, result => this.$emit('submit', result), ()=>{});
    this.$store.commit('setScanner', scanner);
    (async () => {
      try {
        this.$store.state.iface.qrScanner.start().then(() => {
          if (this.$refs.cam === undefined) { // already closed window and ignored the browser permission window
            this.$emit('init', false);
          } else {
            this.$emit('init', true);
          }
        }).catch(() => {
          this.$store.state.iface.qrScanner.stop();
          this.$emit('init', false);
        })
      } catch (err) {
        this.$emit('init', false);
      }
    })();
  },
  methods: {
    onDecode(decString) {
      if (RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i).test(decString)) {
        this.$emit('submit', decString);
      }
    },
  },
  beforeDestroy() {
    if (this.$store.state.iface.qrScanner !== null) {
      this.$store.state.iface.qrScanner.stop();
    }

  },
  props: {
    id: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      required: true,
    },
  },
  components: {
    // QrScanner,
  },
}
</script>
