<template>
  <div v-if="isLoggedIn && !isUser">
    <div class="mt-3">
      <h1 class="mb-2">
        {{ $t('lan.t') }}
      </h1>
      <ReservationsSearch id="landQrSearch" class="mt-1" :qrText="$t('lan.qrt')" :labelText="$t('lan.search')" :warnText="$t('lan.wnor')" />
    </div>

    <div class="mt-4">
      <h3 class="mb-2">
        {{ $t('lan.t1') }}
      </h3>
      <b-card header-tag="nav" id="landRes" body-class="p-2 p-sm-4">
        <template #header>
          <b-nav card-header tabs>
            <b-nav-item active link-classes="px-2 px-sm-3" exact exact-active-class="active">{{ $t('lan.htd') }}</b-nav-item>
            <b-nav-item to="/reservations/today" link-classes="px-2 px-sm-3" exact exact-active-class="active"><Icon v-if="!isXs" type="res" isNav :isButton="false" /> {{ $t('lan.hmng') }}</b-nav-item>
          </b-nav>
        </template>
        <ReservationsToday :noHitText="$t('lan.rno')" />
      </b-card>
    </div>

    <div class="mt-4">
      <h3 class="mb-2">
        {{ $t('lan.t2') }}
      </h3>
      <b-card header-tag="nav" id="landRes" body-class="p-2 p-sm-4">
        <template #header>
          <b-nav card-header tabs>
            <b-nav-item active link-classes="px-2 px-sm-3" exact exact-active-class="active">{{ $t('lan.htm') }}</b-nav-item>
            <b-nav-item to="/reservations" link-classes="px-2 px-sm-3" exact exact-active-class="active"><Icon v-if="!isXs" type="res" isNav :isButton="false" /> {{ $t('lan.hmng') }}</b-nav-item>
          </b-nav>
        </template>
        <ReservationsTomorrow :noHitText="$t('lan.rno')" />
      </b-card>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/Icon';
import ReservationsSearch from '@/components/shared/ReservationsSearch';
import ReservationsToday from '@/components/reservations/Today';
import ReservationsTomorrow from '@/components/reservations/Tomorrow';

export default {
  computed: {
    isLoggedIn() {
      return this.$store.state.oLogin.isLoggedIn;
    },
    isUser() {
      return this.$store.state.oLogin.isUser;
    },
    isXs() {
      return this.$store.getters.isXs;
    },
  },
  components: {
    ReservationsSearch,
    ReservationsToday,
    ReservationsTomorrow,
    Icon,
  },
}
</script>
