<template>
  <div>
    <b-form class="text-center mx-3" @submit.stop.prevent>
      <b-row class="justify-content-center mb-3">
        <b-col cols="11">
          <b-form-row>
            <label for="searchTextRes">{{ labelText }}</label>
          </b-form-row>
          <b-form-row>
            <b-input-group id="searchInput">
              <b-form-input type="text" id="searchTextRes" :placeHolder="$t('lan.resl')" v-model="iuuid4" autofocus @keyup.enter.native="onSearch" />

              <template #append>
                <b-dropdown variant="primary" id="searchDd" ref="searchQrDd" right lazy menu-class="pb-0"
                    v-if="qrAvailable && !qrErr" @show="onQrReadLoad" @hide="onQrReadHide" no-caret>
                  <template #button-content>
                    <Icon type="qr" hasNoText />
                  </template>
                  <div v-if="gettingQr" class="text-center px-2 px-sm-3" :style="`width:`+qrSize+`px;`">
                    <b-spinner variant="primary" />
                  </div>
                  <QrRead v-if="qrShown" v-show="!gettingQr" id="searchQrReader" @init="onQrReadLoadDone" @submit="onSelect" :size="qrSize" />
                </b-dropdown>
                <b-dropdown variant="primary" ref="btn" @show="onSearch" @hide="onHide" no-caret right lazy :disabled="!val">
                  <template #button-content>
                    <Icon type="sea" /> {{ $t('btn.sea') }}
                  </template>
                  <b-table striped hover class="text-left px-2 px-sm-3 mb-0 overflow-x-hidden" :fixed="isXs" table-class="mr-1" 
                      :items="items" :fields="fields" sticky-header="300px">
                    <template #cell(startAt)="data">
                      <span class="text-nowrap">{{ getDate(data.value) }}</span>
                    </template>
                    <template #cell(uuid4)="data">
                      <b-button type="button" :variant="isXs?`secondary`:`primary`" :id="items.indexOf(data.item)" @click="onSelect(data.item.uuid4)">
                        <span v-if="isXs" class="text-nowrap"><Icon type="shw" hasNoText /></span>
                        <span v-else class="text-nowrap"><Icon type="shw" /> {{ $t('btn.shw') }}</span>
                      </b-button>
                    </template>
                  </b-table>
                </b-dropdown>
              </template>
            </b-input-group>
          </b-form-row>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import Icon from '@/components/Icon';
import { REST } from '@/components/RestCall';
import Query from '@/components/RestQuery';
import { utcToZonedTime } from 'date-fns-tz';
import QrRead from '@/components/QrRead';

export default {
  data() {
    return {
      uuid4: null,
      gettingQr: false,
      qrErr: false,
      qrShown: false,
      qrSize: 250,
      isAvailable: false,
      qrCheck: false,
      items: [],
      btnShown: false,
    }
  },
  computed: {
    fields() {
      return [
        { key: 'startAt', label: this.$t('tab.d'), class: 'px-1 px-sm-3 text-'+(this.isXs?'truncate':'wrap'), thStyle: 'width: 100%;', sortable: true },
        { key: 'uuid4', label: (this.isXs?'':this.$t('tab.a')), class: 'px-1', thStyle: 'width: 40px;z-index:1000', sortable: false }
      ];
    },
    iuuid4: {
      get() {
        return this.uuid4;
      },
      set(v) {
        if (v == null || v == '') {
          this.uuid4 = null;
        } else {
          this.uuid4 = v;
        }
      }
    },
    qrAvailable() {
      return this.isAvailable;
      // return ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices);
    },
    val() {
      return this.uuid4 && typeof this.uuid4 === 'string' && this.uuid4.length >= 4;
    },
    isXs() {
      return this.$store.getters.isXs;
    },
    tz() {
      return this.$store.state.settings.timezone;
    },
  },
  mounted() {
    if (!navigator.mediaDevices) {
      this.isAvailable = false;
      return;
    }
    this.qrCheck = false;
    this.isAvailable = navigator.mediaDevices.enumerateDevices()
        .then(devices => devices.some(device => device.kind === 'videoinput'))
        .catch(() => false);
  },
  methods: {
    getDate(v) {
      if (!v) return '';
      return this.$d(utcToZonedTime(v+'Z', this.tz), 'as');
    },
    onQrReadLoad() {
      this.qrShown = true;
      this.gettingQr = true;
    },
    onQrReadLoadDone(isSuccess) {
      this.qrErr = !isSuccess;
      this.gettingQr = false;
    },
    onQrReadHide() {
      this.qrShown = false;
    },
    onSearch(event) {
      if (this.items.length) {
        // coming from GET promise
        return;
      }
      event.preventDefault();

      let qArray = [];
      if (this.uuid4 !== null && this.uuid4 !== '') {
        qArray.push(['uuid4', 's', this.uuid4]);
        qArray.push(['userUuid4', 'n', this.$store.getters.uuid]);
      } else {
        this.$emit('showWarn', this.warnText);
        return;
      }
      let qObj = Query.setFilter({ p: '10' }, qArray);
      REST.get('/customers/' + this.$store.getters.uuid + '/reservations', { params: qObj }
          ).then(resp => {
            this.items = resp.d.data;
            if (Array.isArray(this.items) && this.items.length === 1) {
              this.onSelect(this.items[0].uuid4);
            } else {
              this.btnShown = true;
              this.$refs.btn.show();
            }
          }).catch(e => {
            if (e.code != 404) {
              this.$emit('showWarn', e.message);
            } else {
              this.uuid4 = null;
            }
          })
    },
    onHide() {
      this.items = [];
    },
    onSelect(uuid4) {
      if (this.btnShown) {
        this.$refs.btn.hide();
      }
      this.btnShown = false;
      if (this.qrCheck === true) {
        return; // wait for last qrCheck to complete
      }
      this.qrCheck = true;
      REST.get('/reservations/' + uuid4 + '/customer'
        ).then(resp => {
          this.$store.commit('setResDetails', resp.d.data);
          this.$router.push('/reservations/'+uuid4);
        }).catch(() => {
          this.$store.commit('showWarn',this.$t('rc.3'));
          setTimeout(() => {
            this.qrCheck = false;
          }, 1000);
        })
    },
  },
  props: {
    qrText: {
      type: String,
      default: null,
    },
    warnText: {
      type: String,
      default: 'Reservation number required.',
    },
    labelText: {
      type: String,
      default: 'Enter reservation number, or QR Code',
    },
  },
  components: {
    Icon,
    QrRead,
  },
}
</script>
